:root{
  --primary-blue: #006C9E;
}

.dashboard-title.ant-typography {
  margin-bottom: 0;
  color: var(--primary-blue);;
}

button.ant-btn.date-filter-button:hover, button.ant-btn.date-filter-button:disabled {
  color: #333333;
  border-color: #d9d9d9;
  height: 38px;
}

button.ant-btn.date-filter-button,
div.ant-select-selector.date-filter-select {
  height: 38px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white;
  color: var(--primary-blue);;
  font-weight: 600;
  font-size: 1.125rem;
}

.ant-select-item-option-content,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  color: var(--primary-blue);;
}

div.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #F3F8FA;
}

button.ant-btn{
  align-items: center;
}

button.ant-btn-link{
  align-items: center;
  color: var(--primary-blue);
}

button.ant-btn-link.text-lg:hover{
  color: var(--primary-blue);
}

.ant-select-selection-item{
  margin: 0 5px 0 5px;
}
.ant-select.ant-select-borderless.inactive-select.ant-select-single.ant-select-show-arrow > div.ant-select-selector {
  padding-left: 0;
}

.ant-select.ant-select-borderless.inactive-select.ant-select-single.ant-select-show-arrow > div.ant-select-selector,
.ant-select.ant-select-borderless.inactive-select.ant-select-single.ant-select-show-arrow > div.ant-select-selector > span.ant-select-selection-placeholder {
  color: #006C9E;
  font-size: 1rem;
}

.ant-select.ant-select-borderless.inactive-select.ant-select-single.ant-select-show-arrow {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
 }

 .ant-picker-input > input {
   color: #BFBFBF
 }

 .ant-select-selection-selected-value {
  width: 100%;
}

.site-filter .ant-select-selection-overflow,
.site-filter .ant-select-selection-placeholder {
  padding-left: 1rem;
}

.site-filter .ant-select-selection-item-content,
.site-filter .ant-select-selection-placeholder,
.site-filter .anticon-close {
  color: #006C9E;
}

.site-filter .anticon-close {
  margin-left: 5px;
}

.site-filter.ant-select-multiple .ant-select-selection-item {
  padding-inline-end: .5rem;
}

.site-filter.ant-select-multiple .ant-select-selection-item,
.site-filter.ant-select-multiple .ant-select-selection-item-remove,
.site-filter.ant-select-multiple .ant-select-selection-item-content,
.site-filter.ant-select-multiple .anticon-close {
  color: white;
  background: #006C9E;
  display: flex;
  justify-content: space-between;
}

.site-filter.site-filter.ant-select-multiple .ant-select-clear {
  margin-top: -0.5rem;
}
.align-center {
  align-items: center;
}

.show-weekend-checkbox{
  margin-left: 15px;
}

.change-week-btn {
  display: inline-flex;
}